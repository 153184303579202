import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogRef } from "@angular/material/dialog";
import { Partner } from "../../../interface/partner";
import { Dictionary } from "../../../interface/dictionary";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  UntypedFormBuilder,
  Validators
} from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject, takeUntil } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { SubmissionsService } from "../../../services/submissions.service";
import { BillingContactPipe } from "../../../pipe/billing-contact";
import { MatButton } from "@angular/material/button";
import { MatError, MatFormField, MatLabel, MatPrefix } from "@angular/material/form-field";
import { MatIcon } from "@angular/material/icon";
import { MatInput } from "@angular/material/input";
import { CommonModule, NgIf } from "@angular/common";
import { NgxMaskDirective } from "ngx-mask";
import { PhonePipe } from "../../../pipe/phone-number";
import { PrimaryContactPipe } from "../../../pipe/primary-contact";
import { MatOption, MatSelect } from "@angular/material/select";
import { Submissions } from "../../../interface/submissions";

interface Request {
  value: string;
  viewValue: string;
}

interface Reporting {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-submissions-add-dialog',
  standalone: true,
  imports: [
    BillingContactPipe,
    FormsModule,
    MatButton,
    MatDialogClose,
    MatError,
    MatFormField,
    MatIcon,
    MatInput,
    MatLabel,
    MatPrefix,
    NgIf,
    NgxMaskDirective,
    PhonePipe,
    PrimaryContactPipe,
    ReactiveFormsModule,
    MatSelect,
    MatOption,
    CommonModule
  ],
  templateUrl: './submissions-add-dialog.component.html',
  styleUrls: ['./submissions-add-dialog.component.scss'] // Corrected property name to styleUrls
})
export class SubmissionsAddDialogComponent implements OnInit, OnDestroy {

  sentForm = false;
  partner: Partner | null = null;
  company: Dictionary | null = null;
  form: FormGroup;
  titleControl: FormControl;
  requestTypeControl: FormControl;
  reportingModuleControl: FormControl;
  contentsControl: FormControl;
  priorityControl: FormControl;
  fileControl: FormControl;
  contentControl: FormControl;
  typeControl: FormControl;
  items: Submissions[] = [];
  reporting: Reporting[] = [];

  private _unsubscribeAll: Subject<void> = new Subject<void>();

  requests: Request[] = [
    { value: '850f9280-b496-480c-b205-de68423415dc', viewValue: 'Błąd w programie' },
    { value: 'eb04437e-662a-48c7-9b8e-dde5865551e5', viewValue: 'Nowe funkcjonalności' },
    { value: 'cf3b065f-4a60-4ee4-8163-74310d364f65', viewValue: 'Zmiany obecnych funkcjonalności' },
  ];

  type: Request[] = [
    { value: 'da2e9b65-a451-4333-8a2c-70604babb27f', viewValue: 'ASF360' },
  ];

  reporting360: Reporting[] = [
    { value: '443531c6-06be-48cb-b01a-6edbb329ff8f', viewValue: 'Posty' },
    { value: '8415d5dd-b766-46dd-9ee1-81e85847f19f', viewValue: 'Pracownicy' },
    { value: '7a43b4f6-9eae-4485-8018-a980312cf56d', viewValue: 'Umowy' },
    { value: '4303b32e-586d-417d-80b1-2feba73803ce', viewValue: 'Globalne' },
    { value: '73827f55-c8e6-4200-a7d5-2a1a321194bc', viewValue: 'Rozliczenia' },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { partnerProfile: Partner, company: Dictionary, contract: string },
    private _matDialogRef: MatDialogRef<SubmissionsAddDialogComponent>,
    private _fb: UntypedFormBuilder,
    private _snack: MatSnackBar,
    private _submissionsService: SubmissionsService
  ) {
    this.partner = _data.partnerProfile;
    this.company = _data.company;
    this.form = this._fb.group({
      title: this.titleControl = this._fb.control('', [Validators.required]),
      requestType: this.requestTypeControl = this._fb.control('', [Validators.required]),
      reportingModule: this.reportingModuleControl = this._fb.control('', [Validators.required]),
      contents: this.contentsControl = this._fb.control('', [Validators.required]),
      priority: this.priorityControl = this._fb.control('', [Validators.required]),
      type: this.typeControl = this._fb.control('', [Validators.required]),
    });
    this.typeControl.setValue('da2e9b65-a451-4333-8a2c-70604babb27f');
  }

  /**
   * On init
   */
  ngOnInit(): void {}

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  sendForm(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this._snack.open('Wypełnij lub popraw wszystkie wymagane pola', '', {
        duration: 2000,
      });
      return;
    }
    this._submissionsService.send(this.form.getRawValue())
      .subscribe(() => {
        this.sentForm = true;
      }, (error: HttpErrorResponse) => {
        this._snack.open('Przy dodawaniu zgłoszenia wystąpił błąd', error.status.toString(), { duration: 2000 });
      });
  }
}
