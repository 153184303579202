<ngx-skeleton-loader
  [class]="class + ' w-full flex'"
  [appearance]=appearance
  [animation]="progress"
  [theme]="{
   'border-radius': rounded,
   width: width,
   height: 'calc(' + height + ' + (' + height + '/2))',
   margin:'0'
   }">

</ngx-skeleton-loader>
