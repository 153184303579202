import { Pipe, PipeTransform } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "../services/auth.service";
import {User} from "../services/user/user.types";

class UserPost {
}

@Pipe({
  name: 'authImage',
  standalone: true
})
export class AuthImagePipe implements PipeTransform {

  constructor(
    private _httpClient: HttpClient,
    private _authServe: AuthService, // our service that provides us with the authorization token
  ) {}

  async transform(src: string, user?: boolean): Promise<string> {
    const token = this._authServe.accessToken;
    const headers = new HttpHeaders({'Authorization': `Bearer ${token}`});
    try {
      const imageBlob = await this._httpClient.get(src, {headers, responseType: 'blob'}).toPromise();
      const reader = new FileReader();
      return new Promise((resolve, reject) => {
        reader.onloadend = () => resolve(reader.result as string);
        reader.readAsDataURL(imageBlob);
      });
    } catch {
      if (user) {
        return null;
      } else {
        return '/assets/images/fallback.jpg';
      }
    }
  }
}
