import {Component, Input, OnInit} from '@angular/core';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";
import {UserService} from "../../services/user/user.service";
import {heightSkeleton} from "./skeleton-data";


@Component({
  selector: 'skeleton',
  standalone: true,
  imports: [
    NgxSkeletonLoaderModule
  ],
  templateUrl: './skeleton.component.html',
  styleUrl: './skeleton.component.scss'
})
export class SkeletonComponent implements OnInit {
  @Input() class: string = '';
  @Input() width: string = '';
  @Input() height: string = '';
  @Input() rounded: '0.75rem' | 'rounded-full' = '0.75rem';
  @Input() appearance: 'circle' | 'line' | '' = '';

  progress: 'pulse' | 'progress' | 'progress-dark' = 'pulse'

  constructor(
    private _userService: UserService
  ) {

  }
  ngOnInit(): void {
    this._userService.isDarkMode$
      .pipe()
      .subscribe((isDarkMode: boolean) => {
        if (isDarkMode) {
          this.progress = 'progress-dark'
        } else {
          this.progress = 'pulse'
        }
      })
  }
}
