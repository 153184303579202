@if (iconHeader){
<div class="grid grid-cols-1 gap-3 justify-items-start">
  <div class="flex items-center p-3 rounded-full cursor-pointer ring-inset ring-primary bg-hover relative group"
       (click)="add()"
       [matTooltip]="'Zgłoś zmianę'">
    <div class="flex items-center rounded-full overflow-hidden">
      <mat-icon
        class="icon-size-5"
        [svgIcon]="'heroicons_outline:megaphone'"></mat-icon>
    </div>
<!--    <div class="absolute group-hover:relative group-hover:duration-400 ml-2 opacity-0 transform translate-x-[-10px] group-hover:translate-x-0 group-hover:opacity-100 transition-all duration-0 ease-out text-gray-700 font-medium">-->
<!--      Zgłoś zmianę-->
<!--    </div>-->
  </div>
</div>
} @else {
  <mat-divider class="my-2"></mat-divider>
  <a mat-menu-item (click)="add()" >Zgłoś zmianę</a>
}

