import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, take} from "rxjs";
import {PostAttachment} from "../interface/post-attachment";
import {HttpClient} from "@angular/common/http";
import {Post} from "../interface/post";

@Injectable({
  providedIn: 'root'
})
export class PostsAttachmentService {
  private _postAttachment: BehaviorSubject<PostAttachment | null> = new BehaviorSubject(null);

  constructor(
    private _httpClient: HttpClient,
  ) { }

  /**
   * Getter for post
   */
  get postAttachment$(): Observable<PostAttachment> {
    return this._postAttachment.asObservable();
  }

  get(uuid: string): Observable<any> {
    const post = this._httpClient.get(`/rest/crm/post_attachment/${uuid}/image`)
      .pipe(take(1));
    post.pipe().subscribe((result: PostAttachment) => {
      this._postAttachment.next(result);
    })
    return post;
  }
}
