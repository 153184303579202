import {Inject, inject} from '@angular/core';
import { CanActivateChildFn, CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { of, switchMap } from 'rxjs';
import {environment} from "environments/environment";


export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) =>
{
  const _authService = inject(AuthService);

  return _authService.check().pipe(
    switchMap((authenticated) =>
    {
      if ( !authenticated )
      {
        // // Redirect to the sign-in page with a redirectUrl param
        // const redirectURL = state.url === '/' ? '' : `${state.url}`;
        // const urlTree = router.parseUrl(`/${redirectURL}`);
        document.location.href = environment.apiUrl + '/sso/';
        return of(false);
      }
      return of(true);
    }),
  );
};
