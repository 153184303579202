import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ENVIRONMENT_INITIALIZER, EnvironmentProviders, inject, Provider } from '@angular/core';
import {AuthInterceptor} from "./auth.interceptor";
import {AuthService} from "./auth.service";
import {ActivatedRoute, provideRouter} from "@angular/router";
import {SplashScreenService} from "./splash-screen";
import {routes} from "../../app.routes";

export const provideAuth = (): Array<Provider | EnvironmentProviders> => {

    return [
      provideRouter(routes),
      provideHttpClient(withInterceptors([AuthInterceptor])),
      {
          provide : ENVIRONMENT_INITIALIZER,
          useValue: () => inject(AuthService),
          multi   : true,
      },
      {
        provide : ENVIRONMENT_INITIALIZER,
        useValue: () => inject(SplashScreenService),
        multi   : true,
      },
    ];
};
