import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {AuthImagePipe} from "../../pipe/auth-image.pipe";
import {map, Subject, takeUntil} from "rxjs";
import {MediaWatcherService} from "../../services/config/media-watcher";
import {DictService} from "../../services/dict.service";
import {DictionaryAsf360} from "../../interface/dictionary-asf360";
import {PostAttachment} from "../../interface/post-attachment";
import {SkeletonComponent} from "../skeleton/skeleton.component";
import {PostAttachmentsFindPipe} from "../../pipe/post-attachments-find.pipe";
import {PostsAttachmentService} from "../../services/posts-attachment.service";
import {PostTagGraphics} from "../../interface/post-tag";
import {UserService} from "../../services/user/user.service";
import {User} from "../../services/user/user.types";
import {PostUsers} from "../../interface/post";

@Component({
  selector: 'app-image',
  standalone: true,
  imports: [
    AsyncPipe,
    AuthImagePipe,
    SkeletonComponent
  ],
  templateUrl: './image.component.html',
  styleUrl: './image.component.scss'
})
export class ImageComponent implements OnInit, OnDestroy{
  @Input() class: string;
  @Input() imageUrl: string;
  @Input() imagePost: PostAttachment;
  @Input() imagePostTagGraphics: PostTagGraphics;
  @Input() imagePostUuid: string;
  @Input() imageType: string;
  @Input() userPost: PostUsers;
  @Input() user: User;
  @Input() width: number;
  @Input() height: number;

  initial: string;
  isLoading: boolean;
  imageResponsive: 'w-full' | 'sm:w-max' = 'w-full';


  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _mediaWatcherService : MediaWatcherService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _postsAttachmentService: PostsAttachmentService,
  ) {
    this.isLoading = true;
  }

  ngOnInit(): void {
    if (this.imagePostUuid){
      this._postsAttachmentService.get(this.imagePostUuid)
        .pipe(
          map((postAttachments: PostAttachment) => {
            this.imagePost = postAttachments;
          })
        )
    }

    this._mediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Set the drawerMode if the given breakpoint is active
        if (matchingAliases.includes('sm')) {
          this.imageResponsive = 'sm:w-max';
        } else {
          this.imageResponsive = 'w-full';
        }

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getInitialUser(user: User): any {
    return user.firstName[0] +  user.lastName[0];
  }

  getInitialUserPost(userPost: PostUsers): any {
    const nameParts = userPost.usersName.split(' ');
    return nameParts.map(part => part.charAt(0).toUpperCase()).join('');
  }

}
