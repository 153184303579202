<!-- Button -->
<button
    mat-icon-button
    [matTooltip]="tooltip || 'Tryb pełnoekranowy'"
    (click)="toggleFullscreen()"
>
    <ng-container [ngTemplateOutlet]="iconTpl || defaultIconTpl"></ng-container>
</button>

<!-- Default icon -->
<ng-template #defaultIconTpl>
    <mat-icon [svgIcon]="'heroicons_outline:arrows-pointing-out'"></mat-icon>
</ng-template>
