import {Company} from "../../../shared/interface/company";

export const company: Company[]= [
  {
    id: '9a67dff7-3c38-4052-a335-0cef93438ff6',
    key: 'asf_premium',
    name: 'ASF Premium sp. z o.o.',
    nip: '821-265-05-86',
    regon: '366616625',
    krs: '0000664284',
    street: 'ul. Armii Krajowej',
    houseNumber: '18',
    apartmentNumber: null,
    zipCode: '08-110',
    city: 'Siedlce',
    postCity: 'Siedlce',
    bill: 'PL 09 1240 2685 1111 0010 7246 5757',
  },
  {
    id: 'a89672f5-e00d-4be4-9194-cb9d29f82165',
    key: 'asf',
    name: 'Adamczuk Serwis Finansowy',
    nip: '821-228-55-37',
    regon: '712481706',
    krs: null,
    street: 'ul. Armii Krajowej',
    houseNumber: '18',
    apartmentNumber: null,
    zipCode: '08-110',
    city: 'Siedlce',
    postCity: 'Siedlce',
    bill: 'PL 73 1240 2685 1111 0000 3666 6304',
  }
];

