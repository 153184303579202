import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatIcon} from "@angular/material/icon";
import {NgIf} from "@angular/common";
import {MatTooltip} from "@angular/material/tooltip";
import {MatSnackBar} from "@angular/material/snack-bar";
import {MatDialog} from "@angular/material/dialog";
import {Subject} from "rxjs";
import {SubmissionsAddDialogComponent} from "../submissions-add-dialog/submissions-add-dialog.component";
import {MatMenuItem} from "@angular/material/menu";
import {MatDivider} from "@angular/material/divider";

@Component({
  selector: 'app-submissions-add',
  standalone: true,
  imports: [
    MatIcon,
    NgIf,
    MatTooltip,
    MatMenuItem,
    MatDivider
  ],
  templateUrl: './submissions-add.component.html',
  styleUrl: './submissions-add.component.scss'
})
export class SubmissionsAddComponent implements OnInit, OnDestroy {
  @Input() iconHeader: boolean = true;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _snack: MatSnackBar,
    private _matDialog: MatDialog,
  ) {

  }


  /**
   * On init
   */
  ngOnInit(): void {

  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  add(){
    this._matDialog.open(SubmissionsAddDialogComponent, {
      autoFocus: false,
      maxHeight: '90vh',
      panelClass: 'join-self-billing-dialog',
      closeOnNavigation: true,
      data: {},
    })
  }
}
