import { inject } from '@angular/core';
import { forkJoin } from 'rxjs';
import {NavigationService} from "./shared/components/navigation";
import {NavigationApiService} from "./shared/services/navigation-api/navigation-api.service";

export const initialDataResolver = () =>
{
    const navigationService = inject(NavigationApiService);

    // Fork join multiple API endpoint calls to wait all of them to finish
    return forkJoin([
        navigationService.get()
    ]);
};
