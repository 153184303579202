import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {NgClass, NgFor, NgIf} from "@angular/common";
import {MatTooltipModule} from "@angular/material/tooltip";
import {Config, ConfigService, Themes} from "../../../services/config/config";
import {Subject, take, takeUntil} from "rxjs";
import {Router} from "@angular/router";
import {Scheme, Theme} from "../../../config/config.types";
import {UserService} from "../../../services/user/user.service";
import {User} from "../../../services/user/user.types";

@Component({
  selector: 'app-settings-navigation',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  templateUrl: './settings-navigation.component.html',
  styleUrl: './settings-navigation.component.scss',
  imports: [MatIconModule, MatButtonModule, NgFor, NgClass, MatTooltipModule, NgIf],

})
export class SettingsNavigationComponent implements OnInit, OnDestroy{

  config: Config;
  layout: string;
  scheme: 'dark' | 'light';
  theme: string;
  themes: Themes;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _router: Router,
    private _configService: ConfigService,
    private _userService: UserService,
  )
  {
  }

  ngOnInit(): void
  {
      this._userService.isDarkMode$
        .pipe()
        .subscribe((isDarkMode: boolean) => {
          const scheme: Scheme = isDarkMode ? 'dark' : 'light'
          this._configService.config = {scheme};
      })

      this._configService.config$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((config: Config) =>
        {
          // Store the config
          this.config = config;
        });
  }



  ngOnDestroy(): void
  {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  setLayout(layout: string): void
  {
    // Clear the 'layout' query param to allow layout changes
    this._router.navigate([], {
      queryParams        : {
        layout: null,
      },
      queryParamsHandling: 'merge',
    }).then(() =>
    {
      // Set the config
      this._configService.config = {layout};
    });
  }

  setScheme(scheme: Scheme): void
  {
    const isDark = scheme === 'dark';
    scheme == 'dark' ? this._userService.setDark() : this._userService.setLight();

    this._configService.config = {scheme};
    this._userService.editUserSetting().pipe().subscribe()
  }

  setTheme(theme: Theme): void
  {
    this._configService.config = {theme};
  }
}
