<button matDialogClose type="button" data-drawer-dismiss="drawer-example" aria-controls="drawer-example" class="z-99 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-full text-sm p-1.5 absolute top-4 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
  <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
       xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"></path>
  </svg>
  <span class="sr-only">Zamknij</span>
</button>
<div class="-m-2 sm:m-0 flex w-full flex-auto flex-col">
  @if (!sentForm){
    <div  class="w-full p-2 text-xl sm:text-2xl text-center">
      Wypełnij formularz zgłoszeniowy
    </div>

    <form class="w-full my-2 sm:my-8 flex flex-col">
      <div class="flex">
        <mat-form-field class="fuse-mat-dense flex-auto">
          <mat-label>Typ zgłoszenia</mat-label>
          <mat-select [formControl]="requestTypeControl">
            <mat-option *ngFor="let request of requests" [value]="request.value">
              {{request.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex">
        <mat-form-field class="fuse-mat-dense flex-auto">
          <mat-label>Moduł zgłoszenia</mat-label>

          <mat-select [formControl]="reportingModuleControl" >
            <mat-option *ngFor="let reporting of reporting360" [value]="reporting.value">
              {{reporting.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex flex-col">
        <mat-form-field class="fuse-mat-dense flex-auto">
          <mat-label>Priorytet</mat-label>
          <mat-select [formControl]="priorityControl">
            <mat-option [value]="2">Wysoki</mat-option>
            <mat-option [value]="3">Normalny</mat-option>
            <mat-option [value]="4">Niski</mat-option>
          </mat-select>
        </mat-form-field>
        <div class="flex flex-row flex-wrap gap-1 mb-4">

        </div>
      </div>
      <div class="flex flex-col">
        <mat-form-field class="example-full-width">
          <mat-label>Tytuł zgłoszenia</mat-label>
          <input [formControl]="titleControl" matInput placeholder="Tytuł" >
        </mat-form-field>

        <mat-form-field class="example-full-width">
          <mat-label>Treść zgłoszenia</mat-label>
          <textarea  [formControl]="contentsControl"  matInput placeholder="Treść" rows="10" autofocus ></textarea>
        </mat-form-field>

      </div>
    </form>
    <div class="flex flex-col sm:flex-row items-center justify-center px-2 gap-2">
      <button
        class="sm:ml-3 px-6 flex w-full sm:w-auto"
        mat-flat-button
        [color]="'primary'"
        (click)="sendForm()"
      >
        Zapisz i wyślij
      </button>
      <button mat-button matDialogClose class="w-full sm:w-auto flex text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium text-sm items-center px-5 py-2.5 text-center"
      > Anuluj</button>
    </div>
  } @else {
    <div  class="w-full p-2 text-xl sm:text-2xl text-center">
      Dziękujemy za przesłanie zgłoszenia.
    </div>

    <div class="flex flex-row gap-2 items-center justify-center px-2 pt-3">
      <button mat-button matDialogClose class="w-full sm:w-auto flex text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium text-sm items-center px-5 py-2.5 text-center"
      > Zamknij</button>
    </div>
  }

</div>
