import { HttpErrorResponse, HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import {Inject, inject} from '@angular/core';
import {catchError, Observable, of, throwError} from 'rxjs';
import {environment} from "../../../environments/environment";
import {AuthService} from "./auth.service";
import {AuthUtils} from "./auth.utils";

/**
 * Intercept
 *
 * @param req
 * @param next
 */
export const AuthInterceptor = (
  req: HttpRequest<unknown>,
  next: HttpHandlerFn
): Observable<HttpEvent<unknown>> => {
    const _authService = inject(AuthService);

    let newReq = req.clone();

    if (_authService.accessToken) {
      // newReq = req.clone({
      //   headers: req.headers.set(
      //     'Authorization',
      //     'Bearer ' + _authService.accessToken
      //   ),
      // });
    }
    return next(newReq).pipe(
        catchError((error) =>
        {
            // Catch "401 and 403 Unauthorized" responses
          if (error instanceof HttpErrorResponse && [401].includes(error.status)) {

                // Sign out
              _authService.signOut();
              // document.location.href = environment.apiUrl + '/sso/';
          }

            return throwError(error);
        }),
    );
};
