import {Injectable} from "@angular/core";
import {MockApiService} from "../../../shared/services/lib/mock-api";
import {cloneDeep} from "lodash-es";
import {company as companyData} from "./data";

@Injectable({providedIn: 'root'})
export class CompanyMockApi {

  private _company: any[] = companyData;

  /**
   * Constructor
   */
  constructor(private _asfMockApiService: MockApiService) {
    // Register Mock API handlers
    this.registerHandlers();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Register Mock API handlers
   */
  registerHandlers(): void {
    this._asfMockApiService
      .onGet('api/company')
      .reply(() => {
        // Clone the categories
        const categories = cloneDeep(this._company);

        return [200, categories];
      });
  }

}
