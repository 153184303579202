import { Injectable } from '@angular/core';
import {Observable, take} from "rxjs";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class SubmissionsService {

  constructor(
    private _httpClient: HttpClient,
  ) { }


  public send(formData: object): Observable<any> {
    return this._httpClient.post(`/rest/crm/submissions`, formData)
      .pipe(
        take(1)
      );
  }
}
