<div class="app-content">
  <button class="navigation-user-avatar" (click)="getProfile()" [matMenuTriggerFor]="userActions"
          [matTooltip]="user ? user.firstName + ' ' + user.lastName : ''">
    <app-image [user]="user" [class]="'w-11 h-11'"></app-image>
  </button>
  <mat-menu
      [xPosition]="'before'"
      #userActions="matMenu">
      <button mat-menu-item>
          <span class="flex flex-col leading-none">
              <span class="mt-1.5  font-bold">{{user?.firstName}} {{user?.lastName}}</span>
              <span class="mt-1.5 text-xs font-medium">{{user?.email}}</span>
          </span>
      </button>

      <ng-container *ngIf="adminRole">
       <mat-divider class="my-2"></mat-divider>
        <a mat-menu-item [href]="apiUrl" target="_blank">
          <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_outline:asf-premium-signet'"></mat-icon>
          <span>asf24.pl</span></a>
      </ng-container>
      <ng-container>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="addSubmission()" >
          <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_outline:megaphone'"></mat-icon>
          <span>Zgłoś zmianę</span></button>
      </ng-container>
      <mat-divider class="my-2"></mat-divider>
      <button
          mat-menu-item
          (click)="signOut()">
          <mat-icon
            class="icon-size-5"
            [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
          <span class="text-warn">Wyloguj się</span>
      </button>
  </mat-menu>
</div>
